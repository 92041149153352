/* Reset default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Gill Sans, sans-serif;
}

html {
  background-color: black;
}

a {
  text-decoration: none;
  color: white;
  font-weight: 100;
}

p {
  margin-bottom: 10px;
  padding: 0 40px;
}

h1 {
  font-size: 3em;
  margin-bottom: 0.4em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.6em;
}

.title {
  font-weight: bold;
}

.app {
  position: relative;
  height: 100vh;
  color: white;
  font-family: Tahoma, sans-serif;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 60%;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: transparent;
}

.navbar-logo img {
  height: 50px;
}

.navbar-links a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  font-weight: bold;
}

.navbar-icons {
  display: flex;
  align-items: center;
}

.navbar-icons svg, .buy-button {
  margin-left: 25px;
  color: white;
  cursor: pointer;

}

.buy-button {
  padding: 8px 16px;
  border: 1px solid white;
  border-radius: 10px;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  margin: -2em 0 -1em 0;
}

.title-container {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #00000000;
  border-radius: 20px;
}

.contract-container {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #000000AA;
  border-radius: 20px;
}

.text-container {
  margin: 0 6em 6em 6em;
  padding: 60px;
  background-color: #256a88de;
  border-radius: 20px;
}

.contract-address {
  display: flex;
  align-items: center;
  font-size: 22px;
  margin-top: 5px;
  justify-content: center;
}

.copy-icon {
  margin-left: 10px;
  height: 14px;
  cursor: pointer;
}

.copy-success {
  margin-top: 5px;
  color: lightgreen;
  font-size: 15px;
}

/* Footer styling */
.footer {
  position: fixed; /* Keeps the footer at the bottom */
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.8); /* Darker background for better contrast */
  padding: 15px; /* Adjusted padding for mobile */
  z-index: 1000;
}

/* Footer text */
.footer p {
  margin: 0;
  padding: 0 10px; /* Smaller padding for mobile */
  font-size: 12px; /* Smaller font size for readability on small screens */
  color: #FFFFFFAA;
}

/* Footer disclaimer or other small text */
.footer .disclaimer {
  font-size: 10px;
  margin-top: 5px;
  color: #FFFFFF99;
}

/* Adjust footer button styling if needed */
.footer button {
  padding: 8px 16px;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 12px;
}


.navbar-icons svg {
  margin-left: 25px;
  color: white;
  cursor: pointer;
  font-size: 30px; /* Adjust this size as needed */
}

.buy-button {
  padding: 8px 16px;
  border: 1px solid white;
  background-color: transparent;
  cursor: pointer;
}

.disclaimer {
  font-size: 10px;
}

/* Hamburger menu styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px;
  transition: 0.3s;
}

/* Navbar links for mobile */
.navbar-links-mobile {
  display: none;
  position: absolute;
  top: 60px; /* adjust based on your navbar height */
  right: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.navbar-links-mobile a {
  color: white;
  margin-bottom: 10px;
  text-decoration: none;
  font-weight: bold;
}


#integrated-terminal {
  width: 400px;
  height: 568px;
}


/* Mobile styles */
@media (max-width: 768px) {
  .content {
    font-size: 70%;
    justify-content: flex-start;
    margin-top: 5em;
    max-width: 100%;
  }

  .title-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #00000000;
    border-radius: 20px;
    max-width: 70%;
  }
  
  .contract-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #000000AA;
    border-radius: 20px;
    max-width: 94%;
  }

  .contract-address {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 5px;
    justify-content: center;
  }

  .text-container {
    margin: 0 3em 10em 3em;
    padding: 20px;
    background-color: #256a88de;
    border-radius: 20px;
  }

  .navbar-links {
    display: none; /* Hide main links */
  }

  .hamburger {
    display: flex;
  }

  .navbar-links-mobile.show {
    display: flex; /* Show links when hamburger is clicked */
  }
}
